export const equiDistantData = [
  {
    id: 1,
    name: '10',
    money: 10
  },
  {
    id: 2,
    name: '100',
    money: 100
  },
  {
    id: 3,
    name: '1千',
    money: 1000
  },
  {
    id: 4,
    name: '1万',
    money: 10000
  },
  {
    id: 5,
    name: '10万',
    money: 100000
  },
  {
    id: 6,
    name: '20',
    money: 20
  },
  {
    id: 7,
    name: '200',
    money: 200
  },
  {
    id: 8,
    name: '2千',
    money: 2000
  },
  {
    id: 9,
    name: '2万',
    money: 20000
  },
  {
    id: 10,
    name: '20万',
    money: 200000
  },

  {
    id: 11,
    name: '30',
    money: 30
  },
  {
    id: 12,
    name: '300',
    money: 300
  },
  {
    id: 13,
    name: '3千',
    money: 3000
  },
  {
    id: 14,
    name: '3万',
    money: 30000
  },
  {
    id: 15,
    name: '30万',
    money: 300000
  },

  {
    id: 16,
    name: '40',
    money: 40
  },
  {
    id: 17,
    name: '400',
    money: 400
  },
  {
    id: 18,
    name: '4千',
    money: 4000
  },
  {
    id: 19,
    name: '4万',
    money: 40000
  },
  {
    id: 20,
    name: '40万',
    money: 400000
  },
  {
    id: 21,
    name: '50',
    money: 50
  },
  {
    id: 22,
    name: '500',
    money: 500
  },
  {
    id: 23,
    name: '5千',
    money: 5000
  },
  {
    id: 24,
    name: '5万',
    money: 50000
  },
  {
    id: 25,
    name: '50万',
    money: 500000
  },

  {
    id: 26,
    name: '60',
    money: 60
  },
  {
    id: 27,
    name: '600',
    money: 600
  },
  {
    id: 28,
    name: '6千',
    money: 6000
  },
  {
    id: 29,
    name: '6万',
    money: 60000
  },
  {
    id: 30,
    name: '60万',
    money: 600000
  },

  {
    id: 31,
    name: '70',
    money: 70
  },
  {
    id: 32,
    name: '700',
    money: 700
  },
  {
    id: 33,
    name: '7千',
    money: 7000
  },
  {
    id: 34,
    name: '7万',
    money: 70000
  },
  {
    id: 35,
    name: '70万',
    money: 700000
  },

  {
    id: 36,
    name: '80',
    money: 80
  },
  {
    id: 37,
    name: '800',
    money: 800
  },
  {
    id: 38,
    name: '8千',
    money: 8000
  },
  {
    id: 39,
    name: '8万',
    money: 80000
  },
  {
    id: 40,
    name: '80万',
    money: 800000
  },
  {
    id: 41,
    name: '90',
    money: 90
  },
  {
    id: 42,
    name: '900',
    money: 900
  },
  {
    id: 43,
    name: '9千',
    money: 9000
  },
  {
    id: 44,
    name: '9万',
    money: 90000
  },
  {
    id: 45,
    name: '90万',
    money: 900000
  }
]
