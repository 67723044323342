<template>
  <div class="view-live-auction-house">
    <div class="mask" v-if="stopEnd=='继续拍卖'"></div>
    <div class="live-auction-house-header">
      <div class="live-auction-house-header-1">
        <img src="../../../assets/images/logo.png" alt="" />
      </div>
      <div class="live-auction-house-header-2">
        <p class="title-1">{{ title }}</p>
        <p class="title-2">{{ auctionHouseName }}</p>
      </div>
      <div class="live-auction-house-header-3">
        <p class="welcome-1">欢迎</p>
        <!-- <p class="welcome-2">
          <a href="javascript:;">拍卖条款</a>
          <span class="line-pad">|</span>
          <a href="javascript:;">服务规则</a>
        </p> -->
      </div>
    </div>
    <div class="live-auction-house-middle-w">
      <div class="live-auction-house-middle">
        <div class="live-auction-house-middle-1">
          <div class="live-auction-house-middle-top">
            <p class="title-lot-1">
              Lot {{ auctionItemOne.lot }}: {{ auctionItemOne.title }}
            </p>
            <div class="img-col">
              <!-- <div class="col-md-3">
                <div class="thumb-container">
                  <div class="thumb">
                    <a href="javascript:;"> -->
              <!-- <viewer :images="imgList"> -->

              <img :src="auctionItemOne.img+imgSize(540,300)" alt="" />
              <!-- </viewer> -->
              <!--      </a>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="info-money">
              <p class="info-money-fw ">拍品估价</p>
              <p class="info-money-fw-2">
                {{ auctionItemOne.assess }}{{ auctionItemOne.currency }}
              </p>
            </div>
            <div class="info-money">
              <p class="info-money-fw">商品描述</p>
              <div class="info-money-fw-text">
                {{ auctionItemOne.item_overview }}
              </div>
            </div>
          </div>
          <div class="live-auction-house-middle-bottom">
            <i class="iconfont icon-xiangzuo text-font" @click="leftScroll"></i>
            <div class=" img-w" v-for="(item, index) in newArry" :key="index">
              <div class="col-md-3">
                <div class="thumb-container">
                  <div class="thumb">
                    <a href="javascript:;">
                      <!-- <viewer :images="imgList"> -->
                      <img :src="item.img+imgSize(150)" alt="" />
                      <!-- </viewer> -->
                    </a>
                  </div>
                </div>
              </div>
              <div class="h-block" v-if="item.status !== '待拍'">
                <p class="p-mar-b">Lot{{ item.lot }}-{{ item.status }}</p>
                <p v-if="item.status === '成交'">{{ item.price }}</p>
              </div>
            </div>
            <i class="iconfont icon-xiangyou text-font" @click="rightScroll"></i>
          </div>
        </div>
        <div class="live-auction-house-middle-2">
          <div class="live-auction-house-middle-2-top">
            <div class="live-message" v-for="(item, index) in messageListData" :key="index" :class="
                item.message_type === 4
                  ? 'live-message-1'
                  : item.message_type === 2
                  ? 'live-cda'
                  : item.message_type === 1
                  ? 'live-message-2'
                  : item.message_type === 3
                  ? 'live-message-3'
                  : ''
              ">
              <span> {{ item.content }}</span>
            </div>
          </div>
          <div class="live-auction-house-middle-2-bottom">
            <div class="message-send">
              <input type="text" name="" id="" v-model="textFirst" @focus="keydownWatch=false"
                @blur="keydownWatch=true" />
              <button @click="sendMessage(1)" :disabled="disabledShow">
                发送（I）
              </button>
            </div>
            <div class="message-send">
              <input type="text" name="" id="" v-model="textFirstO" @focus="keydownWatch=false"
                @blur="keydownWatch=true" />
              <button @click="sendMessage(2)" :disabled="disabledShow">
                发送（O）
              </button>
            </div>
            <div class="message-send">
              <input type="text" name="" id="" v-model="customContent" placeholder="自定义内容" @focus="keydownWatch=false"
                @blur="keydownWatch=true" />
              <button @click="sendMessage(3)" :disabled="disabledShow">
                发送（P）
              </button>
            </div>
            <div class="message-send">
              <button class="start-send" @click="updateAuctionStatus()" :disabled="disabledShow && endShow">
                {{ start }}
              </button>
            </div>
            <div class="message-send suspension">
              <button class="start-send" @click="auctionSuspended()" :disabled="disabledShow">
                {{ stopEnd }}
              </button>
            </div>
          </div>
        </div>
        <div class="live-auction-house-middle-3">
          <div class="live-auction-house-middle-3-top">
            <span>竞价阶梯</span>
            <div class="live-auction-house-middle-3-top-right">
              <a href="javascript:;" v-for="(item, index) in moneyList"
                onkeydown="javascript:if(event.keyCode == 32 || event.keyCode == 13)event.returnValue = false;"
                :key="index" @click="toggle(item.id)" :class="{ active: item.id === num }">{{ item.name }}</a>
            </div>
            <div class="equidistant" v-show="toggleShow" ref="treeWrap">
              <a href="javascript:;" class="more-block" v-for="(item, index) in equiDistantData" :key="index"
                :class="{ active: item.id === equiDistantNum }"
                @click="equiDistant(item.id, item.money)">{{ item.name }}</a>
            </div>
          </div>
          <div class="live-auction-house-middle-3-middle">
            <div class="live-auction-house-middle-3-middle-top">
              <div class="price" v-if="initialPriceShow">
                <span>起拍价格</span>
                <div class="price-money">
                  <!-- <p>
                    {{ currentPrice | ThousandSeparator
                    }}{{ auctionItemOne.currency }}
                  </p> -->
                  <input type="text" v-model="currentPriceInput" @keyup.enter="priceChange()" :disabled="disabledShow"
                    maxlength="14" @focus="keydownWatch=false" @blur="keydownWatch=true" />
                  <span class="dol-money">{{ auctionItemOne.currency }}</span>
                  <p>约合人民币{{ priceCountData | ThousandSeparator }}</p>
                </div>
                <button class="live-auction-house-middle-3-middle-bottom-right" :disabled="disabledShow"
                  @click="priceChange()">
                  改价
                </button>
              </div>
              <div class="price" v-else>
                <span>当前价格</span>
                <div class="price-money">
                  <p>
                    {{ currentPrice | ThousandSeparator
                    }}{{ auctionItemOne.currency }}
                  </p>
                  <p>约合人民币{{ priceCountData | ThousandSeparator }}</p>
                </div>
              </div>
              <div class="price">
                <span>下口价格</span>
                <div class="price-money">
                  <!-- @blur="blurInput()" -->
                  <input type="text" v-model="ThousandInput" @keyup.enter="onInput()" @focus="keydownWatch=false"
                    @blur="keydownWatch=true" />
                  <span class="dol-money">{{ auctionItemOne.currency }}</span>
                  <p class="dol-money-2">
                    约合人民币{{ priceCountDataTwo | ThousandSeparator }}
                  </p>
                </div>
              </div>
            </div>
            <div class="middle-3-middle-bottom-box">
              <button class="live-auction-house-middle-3-middle-bottom" @click="onSiteBidding()"
                :disabled="disabledShow">
                现场出价（L）
              </button>
            </div>
          </div>
          <div class="live-auction-house-middle-3-bottom">
            <button class="btn-wid btn-wight" @click="btnFunction()" :disabled="disabledShow">
              成交（J）
            </button>
            <button @click="BeatBtn(1)" :disabled="disabledShow">流拍（B）</button>
            <button @click="BeatBtn(2)" :disabled="disabledShow">撤拍</button>
            <button class="btn-wid" @click="BeatBtn(4)" :disabled="disabledShow">
              改为现场
            </button>
            <button :disabled="disabledShow" @click="backOut()">回退</button>
            <button @click="BeatBtn(3)" :disabled="disabledShow">选拍</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 选拍弹框 -->
    <el-dialog title="请选择拍品" :visible.sync="lotShow" width="30%">
      <el-form :model="form">
        <el-form-item label="Lot号" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off" placeholder="请输入Lot号码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="lotShow = false">取 消</el-button>
        <el-button type="primary" @click="selectionOK">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {
    equiDistantData
  } from '../../../utils/equiDistant'
  import {
    auctionInfoList,
    getLiveAuctionItemList,
    liveAuctionSellerCheck
  } from '../../../api/index'
  import utils from '@/utils/utils'
  export default {
    name: 'liveAuctionHouse',
    data() {
      return {
        value1: 1,
        imgList: [],
        moneyList: [{
            id: 'A',
            name: '默认'
          },
          {
            id: 'B',
            name: '258'
          },

          {
            id: 'C',
            name: '等距'
          }
        ],
        num: 'A',
        money: 0,
        webSock: null,
        messageListData: [],
        currentPrice: 0,
        auctionItemOne: {},
        priceCountData: 0,
        priceCountDataTwo: 0,
        data: '',
        customContent: '',
        start: '',
        stopEnd: '',
        title: '',
        itemListO: [{
            id: 1,
            img: require('../../../assets/images/13.png')
          },

          {
            id: 2,
            img: require('../../../assets/images/14.png')
          },

          {
            id: 3,
            img: require('../../../assets/images/15.png')
          },

          {
            id: 4,
            img: require('../../../assets/images/16.png')
          },

          {
            id: 5,
            img: require('../../../assets/images/17.png')
          },

          {
            id: 6,
            img: require('../../../assets/images/18.png')
          }
        ],
        itemList: [],
        // ******************
        maxClickNum: 0, // 最大点击次数
        lastLeft: 0, // 上次滑动距离
        clickNum: 0, // 点击次数
        nextItem: 0,
        toggleShow: false,
        equiDistantData: [],
        equiDistantNum: 0,
        equiDistantValue: 0,
        newArry: [],
        currentPage: 1,
        pageSize: 3,
        pageSizeNum: 1,
        lotShow: false,
        form: {
          name: ''
        },
        formLabelWidth: '50px',
        formLabelWidth2: '60px',
        initialPriceShow: null,
        lockReconnect: false, // 是否真正建立连接
        timeout: 30 * 1000, // 30秒一次心跳
        timeoutObj: null, // 心跳心跳倒计时
        serverTimeoutObj: null, // 心跳倒计时
        timeoutnum: null, // 断开 重连倒计时
        disabledShow: null,
        endShow: false,
        auctionHouseName: '',
        textFirst: '即将落槌，请迅速出价',
        textFirstO: '最后一次',
        nowLot: '',
        currentPriceOld: '',
        keydownWatch: true // 是否监听ijb按键
      }
    },
    computed: {
      currentPriceInput: { // 添加千分号
        get: function() {
          if (this.currentPrice == undefined) return
          return this.currentPrice.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
        },
        set: function(value) {
          this.currentPrice = value.toString().replace(/,/gi, '')
          this.currentPrice = this.currentPrice.replace(/[^\d]/g, '')
          this.currentPrice = this.currentPrice.replace(/^0\d/, '0')
          this.priceCount() // 重新计算约合人民币
        }
      },
      ThousandInput: {
        get: function() {
          return (
            this.money &&
            this.money
            .toString()
            .replace(/(^|\s)\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
          )
        },
        set: function(value) {
          this.money = value
        }
      }
    },
    created() {
      this.equiDistantData = equiDistantData
      this.title = this.$route.query.title

      if (localStorage.getItem('sige')) {
        this.num = 'A'
        localStorage.removeItem('moneyI')
      } else {
        if (localStorage.getItem('numId')) {
          this.num = localStorage.getItem('numId')
        }
      }
      if (!localStorage.getItem('numId2')) {
        localStorage.setItem('numId2', 'A')
      }

      // this.initWebSocket()
      this.liveAuctionSellerCheck()
      if (localStorage.getItem('moneyI')) {
        this.equiDistantValue = Number(localStorage.getItem('moneyI'))
        this.equiDistantNum = Number(localStorage.getItem('equiDistantNumId'))
        this.equiDistantCount()
      }
      this.auctionInfoList()
    },
    mounted() {
      const _this = this
      document.addEventListener('mouseup', e => {
        const tree = this.$refs.treeWrap
        if (tree) {
          if (!tree.contains(e.target)) {
            _this.toggleShow = false
          }
        }
      })
      this.shortcutKey() // 启用快捷键
    },
    destroyed() {
      this.webSock.close() // 离开路由之后断开websocket连接
      localStorage.removeItem('numId')
      localStorage.removeItem('numId2')
      localStorage.removeItem('moneyI')
      localStorage.removeItem('equiDistantNumId')
      clearInterval(this.timeoutObj)
    },
    watch: {
      money: {
        handler(newV, oldV) {
          if (newV !== oldV) {
            this.nextRMB()
          }
        }
      }
    },

    filters: {
      // 第二种
      Statistic1(num) {
        var f = parseFloat(num)
        f = Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2) // n 幂
        var s = f.toString()
        var rs = s.indexOf('.')
        // 判定如果是整数，增加小数点再补0
        if (rs < 0) {
          rs = s.length
          s += '.'
        }
        while (s.length <= rs + 2) {
          s += '0'
        }
        return s
      },
      ThousandSeparator(input) {
        return (
          input &&
          input
          .toString()
          .replace(/(^|\s)\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
        )
      }
    },
    methods: {
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      shortcutKey() { // 监听键盘触发快捷键
        document.onkeydown = (e) => {
          const e1 = e || event || window.event || arguments.callee.caller.arguments[0]
          if (e1 && e1.keyCode) {
            if (!this.keydownWatch) return // 输入框输入值时不触发
            if (this.disabledShow) return // 拍卖会结束和待拍时不触发
            switch (e1.keyCode) {
              case 73: // I
                this.sendMessage(1)
                break
              case 79: // O
                this.sendMessage(2)
                break
              case 80: // P
                this.sendMessage(3)
                break
              case 76: // L现场出价
                this.onSiteBidding()
                break
              case 74: // J成交
                this.btnFunction()
                break
              case 66: // B流拍
                this.BeatBtn(1)
                break
            }
          }
        }
      },
      // 判断是否开启socket
      async liveAuctionSellerCheck() {
        const res = await liveAuctionSellerCheck({
          auctionId: Number(this.$route.query.liveId),
          token: localStorage.getItem('accessToken')
        })
        if (res.is_register === true) {
          this.initWebSocket()
        } else {
          this.$message.error(res.resultMsg)
        }
      },
      // handleClose (done) {
      //   this.$confirm('确认关闭？')
      //     .then(_ => {
      //       done()
      //     })
      //     .catch(_ => {})
      // },
      toggle(id) {
        this.num = id
        localStorage.setItem('numId', id)
        localStorage.removeItem('sige')
        if (id === 'C') {
          this.equiDistantValue = 0
          this.toggleShow = !this.toggleShow
          // this.data = 'change-bidding' + ',' + this.auctionItemOne.id + ',' + '-1' + ',' + this.$route.query.liveId + ',' + 'C'
        } else {
          this.equiDistantNum = 0
          this.toggleShow = false
          localStorage.removeItem('moneyI')
          localStorage.setItem('numId2', id)
        }
        if (id === 'A') {
          this.data = utils.baseEncode(
            'change-bidding' +
            ',' +
            this.auctionItemOne.id +
            ',' +
            '-1' +
            ',' +
            this.$route.query.liveId +
            ',' +
            'A'
          )
          this.webSock.send(this.data)
        } else if (id === 'B') {
          this.data = utils.baseEncode(
            'change-bidding' +
            ',' +
            this.auctionItemOne.id +
            ',' +
            '-1' +
            ',' +
            this.$route.query.liveId +
            ',' +
            'B'
          )
          this.webSock.send(this.data)
        }
        this.biddingLadder()
      },

      initWebSocket() {
        // 初始化weoSocket
        //const url = 'wss://bid.test.easyebid.com/run'
        const url = 'wss://bid.easyebid.com/run'
        this.webSock = new WebSocket(url)
        this.webSock.onmessage = this.webSocketOnmessage
        this.webSock.onopen = this.webSocketOnopen
        this.webSock.onerror = this.webSocketOnerror
        this.webSock.onclose = this.webSocketClose
      },
      webSocketOnopen() {
        // 连接建立之后执行send方法发送数据
        const actions =
          'initialization' +
          ',' +
          this.$route.query.firstItemId +
          ',' +
          '-1' +
          ',' +
          this.$route.query.liveId
        const encode = utils.baseEncode(actions)
        this.webSocketSend(encode)
        // this.startK()
      },
      webSocketOnerror() {
        // 连接建立失败重连
        // this.reconnect()
      },
      async getLiveAuctionItemList() {
        const res = await getLiveAuctionItemList({
          auctionId: Number(this.$route.query.liveId)
        })
        if (res.code === 0) {
          localStorage.setItem(
            'auctionItemList',
            JSON.stringify(res.data.auction_item_list)
          )
          this.auctionItemListFor(
            JSON.parse(localStorage.getItem('auctionItemList'))
          )
          this.itemList = JSON.parse(localStorage.getItem('auctionItemList'))
          const nowItem = this.itemList.find(item => item.id == this.nextItem) // 获取当前拍品列表
          this.nowLot = `Lot ${nowItem.lot}` // 赋值当前拍品lot号
          this.getResultsData()
        } else {
          this.$message.error(res.msg)
        }
      },
      webSocketOnmessage(e) {
        // 数据接收
        const reData = utils.baseDecode(e.data)
        if (reData.data === '' || reData.data.return_type === 'login') {
          if (reData.msg !== '出价必须大于当前最高出价') {
            this.timeoutObj = setTimeout(() => {
              this.webSock.send(utils.baseEncode('heartbeat'))
            }, 20000)
          }
        }
        if (reData.code === 0 && reData.data !== '') {
          this.messageListData = reData.data.message_list
          this.currentPrice = reData.data.current_price
          this.currentPriceOld = this.currentPrice
          if (reData.data.current_price !== undefined) {
            localStorage.setItem('currentPrice', reData.data.current_price)
          }
          if (reData.data.message_list !== undefined) {
            localStorage.setItem(
              'messageListData',
              JSON.stringify(reData.data.message_list)
            )
          }
          if (
            reData.data.return_type === 'start' ||
            reData.data.auction_status === 2
          ) {
            this.disabledShow = false
            this.start = '结束拍卖'
          } else if (
            reData.data.return_type === 'login' &&
            reData.data.auction_status !== 2
          ) {
            this.start = '点击开始拍卖'
          } else if (reData.data.return_type === 'end') {
            // this.$message.error('拍卖结束')

            this.start = '点击开始拍卖'
            this.currentPrice = Number(
              JSON.parse(localStorage.getItem('currentPrice'))
            )
          } else if (reData.data.return_type === 'deal') {
            this.start = '点击开始拍卖'
            this.equiDistantNum = 0
          } else if (reData.data.return_type === 'suspend') {
            this.stopEnd = '继续拍卖'
            this.currentPrice = Number(localStorage.getItem('currentPrice'))
          } else if (reData.data.return_type === 'continue') {
            this.stopEnd = '暂停拍卖'
            this.currentPrice = Number(
              JSON.parse(localStorage.getItem('currentPrice'))
            )
          } else if (reData.data.return_type === 'selected-shot') {
            this.messageListData = JSON.parse(
              localStorage.getItem('messageListData')
            )
            this.currentPrice = Number(
              JSON.parse(localStorage.getItem('currentPrice'))
            )
          } else if (reData.data.return_type === 'deal_after') {
            this.num = 1
            this.toggleShow = false
          }
          if (reData.data.return_type === 'change-to-site') {
            this.currentPrice = Number(
              JSON.parse(localStorage.getItem('currentPrice'))
            )
          }
          if (reData.data.is_suspend !== undefined) {
            if (reData.data.is_suspend === true) {
              this.stopEnd = '继续拍卖'
            } else {
              this.stopEnd = '暂停拍卖'
            }
          }
          if (reData.data.return_type === 'deal') {
            this.equiDistantNum = 0
          }
          if (reData.data.is_auction_list === true) {
            this.getLiveAuctionItemList()
          }

          if (reData.data.bidding !== undefined) {
            localStorage.setItem('bidding', reData.data.bidding)
            this.num = localStorage.getItem('bidding')
          }
          if (reData.data.exchange_rate !== undefined) {
            localStorage.setItem(
              'exchangeRate',
              JSON.stringify(reData.data.exchange_rate)
            )
          }
          if (reData.data.auction_item_list !== undefined) {
            localStorage.setItem(
              'auctionItemList',
              JSON.stringify(reData.data.auction_item_list)
            )
          }
          // this.auctionItemListFor(JSON.parse(localStorage.getItem('auctionItemList')))
          // this.itemList = JSON.parse(localStorage.getItem('auctionItemList'))
          if (reData.data.offer_data !== undefined) {
            localStorage.setItem(
              'offerData',
              JSON.stringify(reData.data.offer_data)
            )
          }
          if (reData.data.offer_data_258 !== undefined) {
            localStorage.setItem(
              'offerData258',
              JSON.stringify(reData.data.offer_data_258)
            )
          }

          if (reData.data.next_item !== undefined) {
            this.nextItem = reData.data.next_item
          }
          if (reData.data.initial_price !== undefined) {
            if (
              reData.data.initial_price !== 0 ||
              reData.data.current_price === '0'
            ) {
              this.initialPriceShow = true
              localStorage.setItem('currentPrice', reData.data.initial_price)
              this.currentPrice = Number(
                JSON.parse(localStorage.getItem('currentPrice'))
              )
            } else {
              this.initialPriceShow = false
            }
          } else if (reData.data.return_type === 'offer') {
            this.initialPriceShow = false
            this.currentPrice = Number(
              JSON.parse(localStorage.getItem('currentPrice'))
            )
          }
          // if (reData.data.initial_price === 0 && reData.data.current_price === '0') {
          //   this.initialPriceShow = true
          //   localStorage.setItem('currentPrice', reData.data.initial_price)
          //   this.currentPrice = Number(JSON.parse(localStorage.getItem('currentPrice')))
          // }
          if (reData.data.return_type === 'send-message') {
            this.currentPrice = Number(localStorage.getItem('currentPrice'))
          }
          if (reData.data.return_type === 'change-bidding') {
            this.currentPrice = Number(localStorage.getItem('currentPrice'))
          }
          if (reData.data.return_type === 'change-equidistant') {
            this.currentPrice = Number(localStorage.getItem('currentPrice'))
          }
          if (reData.data.auction_status === 3) {
            this.disabledShow = true
            this.endShow = true
          } else if (reData.data.auction_status === 1) {
            this.disabledShow = true
            this.endShow = false
          } else {
            this.disabledShow = false
            this.endShow = false
          }
          if (
            reData.data.return_type === 'stream-beat' ||
            reData.data.return_type === 'withdraw-shooting'
          ) {
            this.num = 1
            this.equiDistantNum = 0
          }
          if (
            reData.data.return_type === 'suspend' ||
            reData.data.return_type === 'continue'
          ) {
            this.currentPrice = Number(localStorage.getItem('currentPrice'))
          }
          if (reData.data.return_type === 'suspend') {
            clearInterval(this.timeoutObj)
          }
          if (reData.data.return_type === 'continue') {
            this.timeoutObj = setTimeout(() => {
              this.webSock.send(utils.baseEncode('heartbeat'))
            }, 20000)
          }
          this.priceCount()
          this.getResultsData()
          this.biddingLadder()
          this.getcurrentPage()
          if (reData.data.return_type === 'end') {
            // this.reset()
            clearInterval(this.timeoutObj)
          }
          // const auctionItemList = JSON.parse(localStorage.getItem('auctionItemList'))
          // for (let index = 0; index < auctionItemList.length; index++) {
          //   if (auctionItemList[index].id === this.nextItem) {
          //     this.newArry = auctionItemList.splice(index, 3)
          //   }
          // }
          // const index = this.newArry.findIndex(item => item.id === this.nextItem)
          // if (index === -1) {
          //   this.rightScroll()
          // }
          if (reData.data.auction_status === 3) {
            clearInterval(this.timeoutObj)
          }
        } else {
          if (reData.data !== '') {
            this.$message.error(reData.msg)
          }
          if (reData.code === -1) {
            this.$message.error(reData.msg)
          }
          if (reData.msg === '此拍品暂停拍卖') {
            this.stopEnd = '继续拍卖'
          }
        }
      },
      webSocketSend(Data) {
        // 数据发送
        this.webSock.send(Data)
      },
      webSocketClose(e) {
        // 关闭
        // this.reconnect() // 重连
      },
      reconnect() {
        // 重新连接
        var that = this
        if (that.lockReconnect) {
          return
        }
        that.lockReconnect = true
        // 没连接上会一直重连，设置延迟避免请求过多
        that.timeoutnum && clearTimeout(that.timeoutnum)
        that.timeoutnum = setTimeout(function() {
          that.initWebSocket() // 新连接

          that.lockReconnect = false
        }, 5000)
      },
      reset() {
        // 重置心跳
        var that = this
        clearTimeout(that.timeoutObj) // 清除时间
        clearTimeout(that.serverTimeoutObj) // 清除时间
        that.startK() // 重启心跳
      },
      startK() {
        // 开启心跳
        var self = this
        self.timeoutObj && clearTimeout(self.timeoutObj)
        self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj)
        self.timeoutObj = setTimeout(function() {
          // 这里发送一个心跳，后端收到后，返回一个心跳消息，
          if (self.webSock.readyState === 1) {
            // 如果连接正常
            self.webSock.send('heartbeat')
          }
          self.serverTimeoutObj = setTimeout(function() {
            // 超时关闭
            self.webSock.close()
          }, self.timeout)
        }, self.timeout)
      },
      // 只拿一个拍品
      auctionItemListFor(item) {
        for (let index = 0; index < item.length; index++) {
          if (item[index].status === '待拍') {
            this.auctionItemOne = item[index]
            return
          }
        }
      },
      // 258阶梯循环取得下一口价格
      offerData258() {
        const params = JSON.parse(localStorage.getItem('offerData258'))
        for (let index = 0; index < params.length; index++) {
          if (Number(params[index]) > Number(this.currentPrice)) {
            this.money = Number(params[index])
            this.nextRMB()
            return
          }
        }
      },
      // 计算下口价格人民币
      nextRMB() {
        const str = this.money.toString()
        this.money = str.replace(/,/g, '')
        this.priceCountDataTwo = utils.toDecimal2(
          Number(this.money) * Number(localStorage.getItem('exchangeRate'))
        )
      },
      // 取得下口价格
      nextPrice(item) {
        for (let index = 0; index < item.length; index++) {
          if (Number(item[index]) > Number(this.currentPrice)) {
            this.money = Number(item[index])
            this.nextRMB()
            return
          }
        }
      },
      // 竞价阶梯切换调用封装
      biddingLadder() {
        if (this.num === 'B') {
          this.offerData258()
        } else if (this.num === 'A') {
          this.nextPrice(JSON.parse(localStorage.getItem('offerData')))
        } else if (this.num === 'C') {
          if (this.equiDistantValue !== 0) {
            this.equiDistantCount()
          } else {
            if (localStorage.getItem('bidding') === 'A') {
              this.nextPrice(JSON.parse(localStorage.getItem('offerData')))
            } else if (localStorage.getItem('bidding') === 'B') {
              this.offerData258()
            }
          }
        }
      },
      // 计算约合人民币
      priceCount() {
        if (this.currentPrice !== undefined) {
          // this.currentPrice = this.currentPrice.replace(/,/g, '')
          this.priceCountData = utils.toDecimal2(
            Number(this.currentPrice) *
            Number(localStorage.getItem('exchangeRate'))
          )
        } else {
          this.priceCountData = 0
        }
      },
      onInput() {
        this.onSiteBidding()
      },
      // 现场出价
      onSiteBidding() {
        let params = ''
        // params = this.money
        if (this.initialPriceShow === true) {
          if (localStorage.getItem('currentPrice') === '0') {
            params = this.money
          } else {
            params = this.currentPrice
          }
        } else {
          params = this.money
        }
        if (this.currentPage === 0) {
          params = this.money
        }

        // if (this.num === 3) {
        //   if (this.equiDistantValue === 0) {
        //     this.$message.error('请选择等距价格')
        //     return
        //   }
        // }
        this.data = utils.baseEncode(
          'offer' +
          ',' +
          this.auctionItemOne.id +
          ',' +
          '-1' +
          ',' +
          this.$route.query.liveId +
          ',' +
          params
        )
        // this.data = this.$Base64.encode()
        this.webSock.send(this.data)
        this.priceCount()
        this.nextRMB()
      },
      // 改价
      priceChange() {
        if (this.currentPrice == this.currentPriceOld) return
        if (this.currentPrice == '') return this.$message.error('价格不能为空')
        this.data = utils.baseEncode(
          'modify-initial-price' +
          ',' +
          this.nextItem +
          ',' +
          '-1' +
          ',' +
          this.$route.query.liveId +
          ',' +
          Number(this.currentPrice)
        )
        this.webSock.send(this.data)
      },
      // 发送消息
      sendMessage(index) {
        let params = ''
        if (index === 1) {
          params = this.textFirst
        } else if (index === 2) {
          params = this.textFirstO
        } else if (index === 3) {
          if (this.customContent === '') {
            this.$message.error('消息不能为空')
            return
          }
          params = this.customContent
          // this.customContent = ''
        }
        this.data = utils.baseEncode(
          'send-message' +
          ',' +
          this.nextItem +
          ',' +
          '-1' +
          ',' +
          this.$route.query.liveId +
          ',' +
          params
        )
        this.webSock.send(this.data)
      },
      // 开始拍卖
      updateAuctionStatus() {
        if (this.start === '点击开始拍卖') {
          this.data = utils.baseEncode(
            'start' +
            ',' +
            this.nextItem +
            ',' +
            '-1' +
            ',' +
            this.$route.query.liveId
          )
          this.webSock.send(this.data)
        } else {
          this.data = utils.baseEncode(
            'end' +
            ',' +
            this.nextItem +
            ',' +
            '-1' +
            ',' +
            this.$route.query.liveId
          )
          this.webSock.send(this.data)
        }
      },
      // 暂停拍卖
      auctionSuspended() {
        if (this.stopEnd === '暂停拍卖') {
          this.data = utils.baseEncode(
            'suspend' +
            ',' +
            this.nextItem +
            ',' +
            '-1' +
            ',' +
            this.$route.query.liveId
          )
          this.webSock.send(this.data)
        } else {
          this.data = utils.baseEncode(
            'continue' +
            ',' +
            (this.nextItem || JSON.parse(localStorage.getItem('nextItem'))) +
            ',' +
            '-1' +
            ',' +
            this.$route.query.liveId
          )
          this.webSock.send(this.data)
        }
      },
      // 成交按钮
      btnFunction() {
        this.data = utils.baseEncode(
          'deal' +
          ',' +
          this.nextItem +
          ',' +
          '-1' +
          ',' +
          this.$route.query.liveId
        )
        this.webSock.send(this.data)
        this.priceCount()
        this.biddingLadder()
        this.nextRMB()
      },
      // 控制台按钮
      BeatBtn(index) {
        if (index === 1) {
          this.data = utils.baseEncode(
            'stream-beat' +
            ',' +
            this.nextItem +
            ',' +
            '-1' +
            ',' +
            this.$route.query.liveId
          )
          this.webSock.send(this.data)
        } else if (index === 2) {
          this.data = utils.baseEncode(
            'withdraw-shooting' +
            ',' +
            this.nextItem +
            ',' +
            '-1' +
            ',' +
            this.$route.query.liveId
          )
          this.webSock.send(this.data)
        } else if (index === 3) {
          this.lotShow = true
          this.form.name = ''
        } else if (index === 4) {
          this.data = utils.baseEncode(
            'change-to-site' +
            ',' +
            this.nextItem +
            ',' +
            '-1' +
            ',' +
            this.$route.query.liveId
          )
          this.webSock.send(this.data)
        }
        this.priceCount()
        // this.biddingLadder()
        // this.nextRMB()
      },
      // 选拍确定
      selectionOK() {
        this.data = utils.baseEncode(
          'selected-shot' +
          ',' +
          this.nextItem +
          ',' +
          '-1' +
          ',' +
          this.$route.query.liveId +
          ',' +
          this.form.name
        )
        this.webSock.send(this.data)
        this.lotShow = false
      },
      // 回退
      backOut() {
        this.data = utils.baseEncode(
          'withdrawal' +
          ',' +
          this.nextItem +
          ',' +
          '-1' +
          ',' +
          this.$route.query.liveId
        )
        this.webSock.send(this.data)
      },
      // 点击左边切换图片
      leftScroll() {
        // this.pageSizeNum--
        // if (this.pageSizeNum === 1) return
        // this.currentPage = this.pageSizeNum
        // this.getResultsData()
        // this.pageSizeNum--
        // if (this.pageSizeNum === 1) return
        // if (this.value1 <= 6) return
        const auctionItemList = JSON.parse(
          localStorage.getItem('auctionItemList')
        )
        const count = auctionItemList.length // 列表总数
        const remainder = count % 6 // 分页后余数
        const elsePage = remainder ? 1 : 0 // 有余数需再加一页
        const lastPage = parseInt(count / 6) + elsePage // 最后一页
        if (this.currentPage === lastPage && remainder) {
          // 当前页是最后一页
          this.value1 -= remainder
        } else {
          this.value1 -= 6
        }
        if (this.currentPage === 1) return
        this.currentPage--
        this.getResultsData()
      },
      // 点击右边切换图片
      rightScroll() {
        // this.pageSizeNum++
        // this.currentPage = this.pageSizeNum
        const auctionItemList = JSON.parse(
          localStorage.getItem('auctionItemList')
        )
        const count = auctionItemList.length // 列表总数
        this.currentPage++
        this.value1 += 3
        if (this.value1 > count) {
          this.value1 = count
        }
        this.getResultsData()
      },
      getcurrentPage() {
        // 定位到当前页的数据
        const auctionItemList = JSON.parse(
          localStorage.getItem('auctionItemList')
        )
        const index =
          auctionItemList.findIndex(item => item.id === this.nextItem) + 1 // 第几条数据在直播
        const count = auctionItemList.length // 列表总数
        const remainder = index % 3 // 分页后余数
        const elsePage = remainder ? 1 : 0 // 有余数需再加一页
        this.currentPage = parseInt(index / 3) + elsePage // 当前页
        // 截止到当前页的总数
        if (this.currentPage * 3 > count) {
          this.value1 = count
        } else {
          this.value1 = this.currentPage * 3
        }
        this.getResultsData()
      },
      // 分页
      getResultsData() {
        var that = this
        this.itemList = JSON.parse(localStorage.getItem('auctionItemList'))
        var list = JSON.parse(localStorage.getItem('auctionItemList'))
        this.newArry = list.filter(
          (item, index) =>
          index < that.currentPage * that.pageSize &&
          index >= that.pageSize * (that.currentPage - 1)
        )
        // if (this.newArry.length === 0) {
        //   this.currentPage = 1

        //   this.pageSizeNum = 1
        //   this.getResultsData()
        // }
        if (this.newArry.length === 0) {
          this.currentPage = 1
          const auctionItemList = JSON.parse(
            localStorage.getItem('auctionItemList')
          )
          const count = auctionItemList.length // 列表总数
          if (count < 3) {
            // 第一页数量
            this.value1 = count
          } else {
            this.value1 = 3
          }
          // this.pageSizeNum = 1
          this.getResultsData()
        }
      },
      // 失去焦点
      // blurInput () {
      //   if (this.num === 2) {
      //     this.offerData258()
      //   }
      // },
      // 点击等距
      equiDistant(id, value) {
        this.equiDistantNum = id
        this.equiDistantValue = value
        localStorage.setItem('moneyI', value)
        localStorage.setItem('equiDistantNumId', id)
        this.equiDistantCount()
        this.toggleShow = false

        this.data = utils.baseEncode(
          'change-equidistant' +
          ',' +
          this.auctionItemOne.id +
          ',' +
          '-1' +
          ',' +
          this.$route.query.liveId +
          ',' +
          value
        )
        this.webSock.send(this.data)
      },
      // 选择等距计算
      equiDistantCount() {
        this.money =
          Number(JSON.parse(localStorage.getItem('currentPrice'))) +
          this.equiDistantValue
      },
      // 获取拍卖行

      async auctionInfoList() {
        const res = await auctionInfoList({
          id: Number(this.$route.query.liveId),
          userId: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken'),
          auctionWayId: '',
          brandAuthorId: '',
          stylePeriodId: '',
          materialsCraftId: '',
          placeSourceId: '',
          auctionHouseId: '',
          time: '',
          page: 1,
          pageSize: 40,
          title: '',
          classifyId: '',
          twoClassifyId: ''
        })
        if (res.code === 0) {
          this.auctionHouseName = res.data.auctionHouseName
        } else {
          this.$message.error(res.msg)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .view-live-auction-house {
    p {
      margin-bottom: 0;
    }

    .mask {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      z-index: 1;
    }

    /deep/ .el-dialog__wrapper {
      z-index: 200 !important;
    }

    .live-auction-house-header {
      background: #fff;
      border: 1px solid #dedede;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 13px 100px;

      .live-auction-house-header-1 {
        text-align: left;
        flex: 1;

        img {
          width: 280px;
        }
      }

      .live-auction-house-header-2 {
        flex: 1;

        .title-1 {
          font-size: 24px;
          font-weight: 500;
          color: #333;
          margin-bottom: 5px;
        }

        .title-2 {
          margin-bottom: 0;
          font-size: 20px;
          font-weight: 400;
          color: #333;
        }
      }

      .live-auction-house-header-3 {
        text-align: right;
        flex: 1;

        .welcome-1 {
          margin-bottom: 5px;
          font-size: 24px;
          font-weight: 500;
          color: #333;
        }

        .welcome-2 {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 0;

          a {
            color: #cda156;
          }

          .line-pad {
            padding: 0 10px;
            color: #cda156;
          }
        }
      }
    }

    .live-auction-house-middle-w {
      background: #f9f9f9;
      padding: 30px 90px 58px 90px;

      .live-auction-house-middle {
        display: flex;

        .live-auction-house-middle-1 {
          width: 580px;

          .live-auction-house-middle-top {
            background: #fff;
            border-radius: 20px;
            padding: 20px 20px 6px 20px;
            height: 713px;
            overflow: hidden;
            // overflow-y: scroll;

            .title-lot-1 {
              text-align: left;
              font-size: 24px;
              font-weight: 600;
              color: #333;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .img-col {
              width: 540px;
              height: 300px;
              border: 1px solid #dedede;
              margin-top: 10px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;

              >img {
                max-width: 100%;
                max-height: 100%;
              }

              .col-md-3 {
                margin: 0;
                border: none;
                height: none;
                display: flex;
                // justify-content: center;
                // align-items: center;
                width: 540px;
                height: 300px;

                .thumb-container {
                  width: 100%;
                  // height: 405px;
                  position: relative;
                  // padding-bottom: 100%;
                  margin: 0;
                  border: none;

                  &:hover {
                    box-shadow: none;
                  }

                  .thumb {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    margin: 0;
                    border-top: none;
                    border-bottom: none;
                    border-left: none;

                    // top: 32%;

                    &:hover {
                      box-shadow: none;
                    }

                    a {
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      margin: auto;
                      max-width: 100%;
                      max-height: 100%;

                      // img {
                      //   position: absolute;
                      //   top: 0;
                      //   left: 0;
                      //   right: 0;
                      //   bottom: 0;
                      //   margin: auto;
                      //   max-width: 100%;
                      //   max-height: 100%;
                      // }
                    }
                  }
                }
              }
            }

            .info-money {
              font-size: 20px;
              text-align: left;
              color: #333;
              margin-top: 14px;

              .info-money-fw {
                font-weight: 600;
                margin-bottom: 6px;
              }

              .info-money-fw-2 {
                font-weight: 400;
              }

              .info-money-fw-text::-webkit-scrollbar {
                display: none;
              }

              .info-money-fw-text {
                font-size: 20px;
                font-weight: 400;
                color: #666;
                height: 300px;
                overflow: hidden;
                overflow-y: scroll;
              }
            }
          }

          .live-auction-house-middle-bottom {
            padding: 20px;
            background: #fff;
            width: 100%;
            border-radius: 20px;
            margin-top: 10px;
            display: flex;
            align-items: center;

            .text-font {
              font-size: 25px;
              font-weight: 600;
            }

            .img-w {
              // width: 150px;
              // height: 150px;
              border: 1px solid #dedede;
              margin: 0 10px;
              position: relative;

              .col-md-3 {
                margin: 0;
                border: none;
                height: none;
                display: flex;
                // justify-content: center;
                // align-items: center;
                width: 150px;
                height: 150px;

                .thumb-container {
                  width: 100%;
                  // height: 405px;
                  position: relative;
                  // padding-bottom: 100%;
                  margin: 0;
                  border: none;

                  &:hover {
                    box-shadow: none;
                  }

                  .thumb {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    margin: 0;
                    border-top: none;
                    border-bottom: none;
                    border-left: none;

                    // top: 32%;

                    &:hover {
                      box-shadow: none;
                    }

                    a {
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      margin: auto;
                      max-width: 100%;
                      max-height: 100%;

                      img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        max-width: 100%;
                        max-height: 100%;
                      }
                    }
                  }
                }
              }

              .h-block {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.2);
                // line-height: 150px;
                padding-top: 47px;

                // opacity: 0.3;
                p {
                  color: #fff;
                  font-weight: 600;
                  font-size: 16px;
                }

                .p-mar-b {
                  margin-bottom: 5px;
                }
              }
            }

            .el-carousel__item h3 {
              color: #475669;
              font-size: 18px;
              opacity: 0.75;
              line-height: 300px;
              margin: 0;
            }

            .el-carousel__item:nth-child(2n) {
              background-color: #99a9bf;
            }

            .el-carousel__item:nth-child(2n + 1) {
              background-color: #d3dce6;
            }
          }
        }

        .live-auction-house-middle-2 {
          width: 560px;
          margin: 0 20px;

          .live-auction-house-middle-2-top {
            background: #fff;
            border-radius: 20px;
            width: 100%;
            padding: 20px;
            height: 450px;
            overflow: hidden;

            .live-message {
              width: 520px;
              height: 50px;

              border-radius: 25px;
              margin-bottom: 22px;
              text-align: center;
              line-height: 50px;

              font-size: 18px;
            }

            .live-message-1 {
              background: #dedede;
              color: #26a920;
            }

            .live-message-2 {
              background: #dedede;
              color: #d10000;
            }

            .live-message-3 {
              background: #dedede;
              color: #333;
            }

            .live-cda {
              background: #cda156;
              color: #6e5121;
            }
          }

          .live-auction-house-middle-2-bottom {
            background: #fff;
            border-radius: 20px;
            width: 100%;
            padding: 25px 20px;
            margin-top: 10px;
            .suspension{
              position: relative;
              z-index: 2;
            }
            .message-send {
              margin-bottom: 20px;

              input {
                width: 350px;
                height: 60px;
                outline: none;
                font-size: 20px;
                border: 1px solid #dedede;
                border-radius: 9px;
                padding-left: 20px;
              }

              button {
                width: 150px;
                height: 60px;
                background: #f3f3f3;
                border-radius: 9px;
                border: 1px solid #dedede;
                margin-left: 10px;
                font-size: 20px;
                font-weight: 400;
                color: #333;
                text-align: center;
                line-height: 60px;
              }

              .start-send {
                width: 521px;
                margin-left: 0;

                border: 1px solid #dedede;
              }
            }
          }
        }

        .live-auction-house-middle-3 {
          width: 560px;

          .live-auction-house-middle-3-top {
            width: 100%;
            background: #fff;
            height: 68px;
            border-radius: 20px;
            padding: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            span {
              font-size: 20px;
              font-weight: 600;
              color: #333;
            }

            .live-auction-house-middle-3-top-right {
              a {
                display: inline-block;
                width: 60px;
                height: 28px;
                text-align: center;
                line-height: 28px;
                color: #666;
                font-size: 16px;
                background: #f5f5f5;
                margin-right: 20px;

                &:hover {
                  background: #cda156;
                  color: #fff;
                }
              }

              .active {
                background: #cda156;
                color: #fff;
              }

              a:last-child {
                margin-right: 0;
              }
            }

            .equidistant {
              width: 480px;
              height: 342px;
              background: #fff;
              // padding: 10px 20px;
              border-radius: 16px;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.22);
              z-index: 20;
              position: absolute;
              top: 56px;
              left: 77px;

              .more-block {
                display: inline-block;
                width: 80px;
                height: 26px;
                border-radius: 3px;
                text-align: center;
                border: 1px solid #dedede;
                line-height: 26px;
                font-size: 16px;
                color: #666;
                margin: 10px 5px 1px 5px;

                &:hover {
                  background: #cda156;
                  color: #fff;
                }
              }

              .active {
                background: #cda156;
                color: #fff;
              }
            }
          }

          .live-auction-house-middle-3-middle {
            width: 100%;
            background: #fff;
            border-radius: 20px;
            padding-left: 20px;
            padding-top: 110px;
            padding-right: 20px;
            margin-top: 10px;
            padding-bottom: 20px;

            .live-auction-house-middle-3-middle-top {
              .price {
                display: flex;
                align-items: center;
                margin-bottom: 80px;
                text-align: left;
                position: relative;

                span {
                  font-size: 18px;
                  color: #333;
                  font-weight: 400;
                }

                .price-money {
                  margin-left: 50px;

                  p:first-child {
                    font-size: 30px;
                    font-weight: 600;
                    color: #333;
                    margin-bottom: 20px;
                  }

                  p:last-child {
                    font-size: 16px;
                    color: #333;
                    font-weight: 400;
                  }

                  input {
                    width: 240px;
                    height: 50px;
                    border-radius: 4px;
                    padding-left: 10px;
                    border: 1px solid #dedede;
                    font-weight: 600;
                    color: #333;
                    font-size: 30px;
                    outline: none;
                  }

                  .dol-money {
                    padding-left: 10px;
                    font-size: 30px;
                    color: #333;
                    font-weight: 600;
                  }

                  .dol-money-2 {
                    margin-top: 20px;
                  }
                }
              }

              .price:last-child {
                margin-bottom: 116px;
              }
            }

            .middle-3-middle-bottom-box {
              display: flex;
              align-items: center;
            }

            .live-auction-house-middle-3-middle-bottom {
              width: 520px;
              height: 100px;
              background: #f5f5f5;
              border-radius: 9px;
              border: 1px solid #dedede;
              line-height: 100px;
              text-align: center;
              color: #333;
              font-weight: 600;
              font-size: 20px;
            }

            .live-auction-house-middle-3-middle-bottom-right {
              width: 56px;
              height: 36px;
              background: #f5f5f5;
              border-radius: 9px;
              border: 1px solid #dedede;
              line-height: 36px;
              text-align: center;
              color: #333;
              font-weight: 600;
              font-size: 16px;
              position: absolute;
              right: -10px;
              top: 6px;
            }
          }

          .live-auction-house-middle-3-bottom {
            border-radius: 20px;
            background: #ffffff;
            margin-top: 10px;
            padding: 23px 10px 13px 20px;

            button {
              width: 150px;
              height: 80px;
              background: #f3f3f3;
              border-radius: 9px;
              text-align: center;
              line-height: 80px;
              border: 1px solid #dedede;
              color: #333;
              font-size: 20px;
              // margin-top: 10px;
              margin-bottom: 10px;
              margin-right: 10px;
            }

            .btn-wid {
              width: 200px;
            }

            .btn-wight {
              font-weight: 600;
            }
          }
        }
      }
    }

    .zs-adv {
      margin: 50px auto 0;
      width: 1272px;
      height: 120px;
      // background: url("./image/adv-bg.png") top center no-repeat;

      a {
        margin-top: 58px;
        width: 16px;
        height: 24px;
        opacity: 0.8;
      }

      a:hover {
        opacity: 1;
      }

      .adv-pre {
        float: left;
        margin-right: 20px;
      }

      .adv-next {
        float: right;
      }

      #adv-pad-scroll {
        float: left;
        width: 1200px;
        overflow: hidden;

        .adv-pad {
          width: 2400px;
          height: 120px;

          .adv-pad-item {
            padding: 20px 10px 0px 10px;
            width: 400px;
            height: 100px;
            cursor: pointer;
            animation: all 1.5s;
          }

          .adv-pad-item:hover {
            padding: 10px 5px 0px 10px;
          }
        }
      }
    }
  }
</style>
